import React from "react"
import Navigation from "./layout/Navigation"
import Footer from "./layout/Footer"
import CookieBanner from "./layout/cookie-banner"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <CookieBanner />
        <Navigation location={location} />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
